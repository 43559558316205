import { ref } from 'vue';
import router from '../router'; // Import the router instance

const logs = ref([]); // Store logs as an array of messages

const logger = {
  addLog(message) {
    const timestamp = new Date().toISOString();
    const currentRoute = router.currentRoute.value; // Access the current route
    const pageInfo = currentRoute ? ` (Page: ${currentRoute.fullPath})` : "";
    const logEntry = `[${timestamp}] ${message}${pageInfo}`;
    
    logs.value.push(logEntry);
  },
  getLogs() {
    return logs.value.join('\n');
  },
  clearLogs() {
    logs.value = [];
  }
};

export default logger;
