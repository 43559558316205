import "bootstrap/dist/css/bootstrap.css";
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { useUserStore } from './utils/user';
import './global.css'; 
import logger from '@/utils/logger';  // Import your logging utility if you have one

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);

app.mount('#app');

// Initialize login state from sessionStorage on app startup
const userStore = useUserStore();
userStore.initializeAuth();
userStore.name

function handleError(error, context = 'Global') {
    const userStore = useUserStore();
    const formData = new FormData();

    formData.append('name', userStore?.name || 'Unknown User');
    formData.append('class', userStore?.class || 'Unknown Class');
    formData.append('reason', 'Application Error');
    formData.append('message', error.message || 'No message available');
    formData.append('logs', logger.getLogs() || 'No logs available');

    fetch('http://localhost:8000/sendErrorMail.php', {
        method: 'POST',
        body: formData
    }).then(response => response.text())
      .then(result => {
          if (result !== 'success') {
              console.error('Error notification failed to send:', result);
          }
      }).catch(err => {
          console.error('Error while attempting to send error notification:', err);
      });

    // Optional: Log the error to console for development purposes
    console.error(`Error in ${context}:`, error);
}

// Capture uncaught errors
window.onerror = (message, source, lineno, colno, error) => {
    handleError(error || new Error(message), 'Window');
};

// Capture promise rejections
window.onunhandledrejection = (event) => {
    handleError(event.reason, 'Promise');
};
