<template>
    <!-- Desktop Navigation -->
    <div class="nav-desktop d-flex justify-content-between glass mb-3 mt-3">
      <router-link to="/"><img class="p-2" id="logo" src="@/assets/Logo.png" alt="Logo" /></router-link>
      <div class="align-content-center">
        <router-link class="p-2" to="/profile" style="border-right: 1px solid var(--col1)">Profil</router-link>
        <router-link class="p-2" to="/absence">Abwesenheit</router-link>
        <router-link class="p-2" to="/pickup" style="border-right: 1px solid var(--col1)">Abholung</router-link>
        <a class="p-2" @click="logout">LogOut</a>
      </div>
    </div>
</template>

<script>
import { useUserStore } from '../utils/user';
import logger from '@/utils/logger'; // Assuming a logger utility is in utils

export default {
  mounted() {
    logger.addLog("Navigation component mounted.");
  },
  computed: {
    isAuthenticated() {
      const userStore = useUserStore();
      const authStatus = userStore.isAuthenticated;
      logger.addLog(`Checked authentication status: ${authStatus ? 'Authenticated' : 'Not Authenticated'}`);
      return authStatus;
    }
  },
  methods: {
    navigateTo(route) {
      logger.addLog(`Navigating to ${route}`);
      this.$router.push(route);
    },
    logout() {
      const userStore = useUserStore();
      this.$router.push('/');
      userStore.logout();
      logger.addLog("User logged out successfully.");
    }
  }
};
</script>

<style scoped>
   #logo {
      max-width: 100%;
      height: 75px;
    }

    .list-group-item {
      border: none;
      align-content: center;
    }

.nav-desktop {
  display: flex;
  align-items: center;
}
/* Media Query for Mobile */
@media (max-width: 768px) {
  .nav-desktop {
    display: none !important;
  }

}
</style>
