<template>
  <div class="absence">
    <h1>Abwesenheitsmeldung</h1>
    <form @submit.prevent="submitAbsence" class="d-flex flex-column gap-3" v-if="isClassAvailable">
      <!-- Absence Type Selection -->
      <div>
        <p>Art</p>
        <div>
          <input type="radio" id="type1" value="1" v-model="absenceType" />
          <label for="type1">Kind kommt später</label>
        </div>
        <div>
          <input type="radio" id="type2" value="2" v-model="absenceType" />
          <label for="type2">Kind kommt nicht</label>
        </div>
        <div>
          <input type="radio" id="type3" value="3" v-model="absenceType" />
          <label for="type3">Kind ist krank</label>
        </div>
      </div>

      <!-- Notifiable Selection (only for "Kind ist krank") -->
      <div v-if="absenceType === '3'">
        <p>Meldepflichtige Krankheit?</p>
        <div>
          <input type="radio" id="notifiableYes" value="1" v-model="notifiable" />
          <label for="notifiableYes">Ja</label>
        </div>
        <div>
          <input type="radio" id="notifiableNo" value="2" v-model="notifiable" />
          <label for="notifiableNo">Nein</label>
        </div>
      </div>

      <!-- Fields for "Kind kommt später" -->
      <div v-if="absenceType === '1'">
        <div class="form day-form w-50">
          <input type="date" v-model="absenceDay" name="absence-day" required/>
          <label for="absence-day-input" class="label-name">
              <span class="content-name lock">Tag</span>
          </label>
        </div>
        <div class="form time-form w-50">
          <input v-model="absenceTime" type="time" autocomplete="off" name="absence-time" required/>
          <label for="absence-time" class="label-name">
              <span class="content-name lock">Uhrzeit</span>
          </label>
        </div>
      </div>

      <!-- Date Range for "Kind kommt nicht" and "Kind ist krank" -->
      <div v-if="absenceType === '2' || (absenceType === '3' && notifiable !== '')">
        <div class="form day-form w-50">
          <input v-model="absenceDurationFrom" type="date" autocomplete="off" name="absence-duration-from" required/>
          <label for="absence-duration-from-input" class="label-name">
              <span class="content-name">Dauer von</span>
          </label>
        </div>
        <div class="form day-form w-50">
          <input v-model="absenceDurationUntil" type="date" autocomplete="off" name="absence-duration-until" required/>
          <label for="absence-duration-until-input" class="label-name">
              <span class="content-name">Dauer bis</span>
          </label>
        </div>
      </div>

      <!-- Reason Field for "Kind kommt später" and "Kind kommt nicht" -->
      <div v-if="absenceType === '1' || absenceType === '2'">
        <div class="form">
          <input v-model="absenceReason" type="text" autocomplete="off" name="absence-reason" required/>
          <label for="absence-reason" class="label-name">
              <span class="content-name">Begründung</span>
          </label>
        </div>
      </div>

      <!-- Illness Information for "Kind ist krank" -->
      <div v-if="absenceType === '3' && notifiable === '1'">
        <select v-model="absenceIllness">
          <option value="" disabled>Krankheit wählen</option>
          <option value="COVID-19">COVID-19</option>
          <option value="Hand-Mund-Fuß">Hand-Mund-Fuß</option>
          <option value="Keuchhusten">Keuchhusten</option>
          <option value="Kopflausbefall">Kopflausbefall</option>
          <option value="Masern">Masern</option>
          <option value="Skabies">Skabies</option>
          <option value="Scharlach">Scharlach</option>
          <option value="Windpocken">Windpocken</option>
        </select>
      </div>

      <!-- Quicktest Checkbox -->
      <div v-if="(absenceType === '3' && notifiable === '1' && absenceIllness == 'COVID-19')" class="d-flex w-100 justify-content-between">
          <p>Hat das Kind einen Schnelltest gemacht?</p>
          <input type="checkbox" v-model="quicktest" />
      </div>

      <!-- PCR Checkbox -->
      <div v-if="(absenceType === '3' && notifiable === '1' && absenceIllness == 'COVID-19')" class="d-flex w-100 justify-content-between">
          <p>Hat das Kind einen PCR-Test gemacht?</p>
          <input type="checkbox" v-model="pcr" />
      </div>

      <!-- OGS Checkbox -->
      <div v-if="(absenceType === '3' && notifiable !== '') || absenceType === '1' || absenceType === '2'" class="d-flex w-100 justify-content-between">
          <p>Ist das Kind in der Ganztagsschule?</p>
          <input type="checkbox" v-model="inOGS" />
      </div>

      <!-- Privacy Policy -->
      <div v-if="(absenceType === '3' && notifiable !== '') || absenceType === '1' || absenceType === '2'" class="d-flex w-100 justify-content-between">
          <p>Ich habe die <router-link to="privacy-policy">Datenschutzbestimmungen</router-link> gelesen und erkenne diese an.</p>
          <input type="checkbox" v-model="privacyAccepted" />
      </div>

      <!-- Submit Button -->
      <div class="d-flex justify-content-center">
        <input 
            :disabled="!isFormValid" 
            class="btn-1" 
            id="error-message-submit-button" 
            type="submit" 
            value="Melden" 
            name="submit-error-message" 
        />
      </div>
    </form>

    <p v-else class="feedback-error">Fehler: Es wurde keine Klasse auf IServ zugeteilt. Bitte wenden Sie sich an das Sekretariat.</p>
  </div>
</template>

<script>
import { useUserStore } from '../utils/user';
import logger from '@/utils/logger';

export default {
    data() {
        return {
            absenceType: '',
            absenceDay: '',
            absenceTime: '',
            absenceReason: '',
            absenceDurationFrom: '',
            absenceDurationUntil: '',
            absenceIllness: '',
            quicktest: false,
            pcr: false,
            inOGS: false,
            notifiable: '',
            privacyAccepted: false
        };
    },
    computed: {
        isClassAvailable() {
            const userStore = useUserStore();
            const classStatus = userStore.class !== null;
            logger.addLog(`Absence form: Checked class availability: ${classStatus ? 'Available' : 'Unavailable'}`);
            return classStatus;
        },
        isFormValid() {
            if (!this.privacyAccepted) return false;

            if (this.absenceType === '1') {
                return this.absenceDay && this.absenceTime && this.absenceReason;
            } else if (this.absenceType === '2') {
                return this.absenceDurationFrom && this.absenceDurationUntil && this.absenceReason;
            } else if (this.absenceType === '3') {
                return this.notifiable && this.absenceDurationFrom && this.absenceDurationUntil && (this.notifiable === '1' ? this.absenceIllness : true);
            }

            return false;
        }
    },
    methods: {
        formatDate(date) {
            if (!date) return '';
            const [year, month, day] = date.split("-");
            return `${day}.${month}.${year}`;
        },
        validateDates() {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // Clear time part for accurate comparison

          if (this.absenceDurationFrom) {
              const fromDate = new Date(this.absenceDurationFrom);
              if (fromDate < today) {
                  alert("Fehler: 'Dauer von' darf nicht vor dem heutigen Datum liegen.");
                  logger.addLog("Absence form: Validation failed - 'Dauer von' is before today's date");
                  return false;
              }
          }

          if (this.absenceDurationFrom && this.absenceDurationUntil) {
              const fromDate = new Date(this.absenceDurationFrom);
              const untilDate = new Date(this.absenceDurationUntil);
              if (untilDate < fromDate) {
                  alert("Fehler: 'Dauer bis' darf nicht vor 'Dauer von' liegen.");
                  logger.addLog("Absence form: Validation failed - 'Dauer bis' is before 'Dauer von'");
                  return false;
              }
          }

          logger.addLog("Absence form: Date validation passed.");
          return true;
      },
        async submitAbsence() {
            if (!this.validateDates()) {
                logger.addLog("Absence form: Submission blocked due to invalid dates.");
                return; // Block submission if dates are invalid
            }

            const userStore = useUserStore();
            const formData = new FormData();

            formData.append('name', userStore.name);
            formData.append('class', userStore.class);
            formData.append('absence-type', this.absenceType);
            formData.append('absence-day', this.formatDate(this.absenceDay));
            formData.append('absence-time', this.absenceTime);
            formData.append('absence-reason', this.absenceReason);
            formData.append('absence-duration-from', this.formatDate(this.absenceDurationFrom));
            formData.append('absence-duration-until', this.formatDate(this.absenceDurationUntil));
            formData.append('absence-illness', this.absenceIllness);
            formData.append('absence-quicktest-checkbox', this.quicktest);
            formData.append('absence-pcr-checkbox', this.pcr);

            formData.append('inOGS', this.inOGS ? 'Ja' : 'Nein');
            formData.append('notifiable', this.notifiable);
            formData.append('token', process.env.VUE_APP_SECRET_TOKEN); 

            logger.addLog("Absence form: Submitting form with data.", formData);

            try {
                const response = await fetch(process.env.VUE_APP_BASE_URL + '/sendAbsenceEmail.php', {
                    method: 'POST',
                    body: formData
                });
                const result = await response.text();
                if (result === 'success') {
                    alert("Erfolgreich gemeldet!");
                    logger.addLog("Absence form: Form submitted successfully.");
                    this.clearForm();
                } else {
                    alert("Melden fehlgeschlagen!");
                    logger.addLog("Absence form: Form submission failed with response: " + result);
                }
            } catch (error) {
                alert("Melden fehlgeschlagen!");
                logger.addLog("Absence form: Error during form submission - " + error.message);
            }
        },
        clearForm() {
            this.absenceType = '';
            this.absenceDay = '';
            this.absenceTime = '';
            this.absenceReason = '';
            this.absenceDurationFrom = '';
            this.absenceDurationUntil = '';
            this.absenceIllness = '';
            this.inOGS = false;
            this.notifiable = '';
            this.privacyAccepted = false;
            logger.addLog("Absence form: Form fields cleared after submission.");
        }
    }
};
</script>

  
  <style>
  .feedback-success {
    color: green;
  }
  .feedback-error {
    color: red;
  }
  </style>
  