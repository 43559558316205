<template>
  <div class="profile">
    <h1>Profil</h1>
    <div class="p-4 d-flex">
      <div class="d-flex flex-column justify-content-around m-3">
        <p>Name:</p>
        <p>Klasse:</p>
      </div>
      <div class="d-flex flex-column justify-content-around m-3">
        <p>{{ name }}</p>
        <p  v-if="userClass != null">{{ userClass }}</p>
        <p v-else class="feedback-error">Fehler: Es wurde keine Klasse auf IServ zugeteilt. Bitte wenden Sie sich an das Sekretariat.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from '@/utils/user';

export default {
  data() {
    const userStore = useUserStore();

    return {
      name: userStore.name,
      userClass: userStore.class ? userStore.class : 'Keine Klasse auf IServ hinterlegt. Bitte wenden Sie sich an das Sekretariat.',
    };
  },
}
</script>

<style>

</style>