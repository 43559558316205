<template>
    <div class="imprint">
        <h1>Impressum</h1>
        <p>
            Land Niedersachsen (Dienstanbieter im Sinne des Telemediengesetzes)<br>
            <br>
            Das Land Niedersachsen ist eine juristische Person des öffentlichen Rechts vertreten durch<br>
            <br>
            Schulleitung:<br>
            Karsten Stubben, Schulleiter<br>
            <br>
            Grundschule Waggum<br>
            Claudiusstraße 1<br>
            38110 Braunschweig<br>
            Tel: 05307-6533<br>
            Fax: 05307-980280<br>
            E-Mail: gs.waggum@braunschweig.de<br>
            Schulische Datenschutzbeauftragte: datenschutz@gs-waggum.de<br>
            <br>
            Verwantwortlicher für redaktionell-journalistische Angebot i. S. d. § 55 Abs. 2 Rundfunkstaatsvertrag:<br>
            <br>
            Grundschule Waggum<br>
            Claudiusstraße 1<br>
            38110 Braunschweig<br>
            Tel: 05307-6533<br>
            <br>
            Disclaimer<br>
            <br>
            Diese Website beinhaltet externe Links. Deren Inhalte entziehen sich unserer Einflussnahme, sodass eine Gewähr für solche Inhalte von uns nicht übernommen werden kann.<br>
            <br>
            Die Verantwortung liegt stets beim jeweiligen Betreiber der externen Seiten. Zum Zeitpunkt der Verknüpfung der externen Links waren keine Rechtsverstöße ersichtlich. Die permanente Überwachung fremder Inhalte auf Rechtsverstöße ohne konkrete Hinweise ist uns nicht zumutbar.<br>
            <br>
            Sollten wir Kenntnis von Rechtsverletzungen erlangen, werden wir die entsprechenden Links unverzüglich entfernen.<br>
            <br>
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>