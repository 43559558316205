<template>
    <div class="footer justify-content-center d-flex">
        <router-link class="p-3" to="/imprint">Impressum</router-link>
        <router-link class="p-3" to="/contact">Kontakt</router-link>
        <router-link class="p-3" to="/error">Fehlermeldung</router-link>
        <router-link class="p-3" to="/faq">FAQ</router-link>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>