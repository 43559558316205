<template>
   <nav class="mobile-nav glass" >    
      <router-link to="profile" class="nav-mobile" id="nav-mobile-profil">
        <img src="@/assets/icons/user.png" />
      </router-link>
      <router-link to="error" class="nav-mobile" id="nav-mobile-test">
        <img src="@/assets/icons/ausruf.png" />
      </router-link>
      <router-link to="absence" class="nav-mobile" id="nav-mobile-abwesenheit">
        <img src="@/assets/icons/calendar.png" />
      </router-link>
      <router-link to="pickup" class="nav-mobile" id="nav-mobile-abholung">
        <img src="@/assets/icons/clock.png" />
      </router-link>
      <a @click="logout" class="nav-mobile" id="nav-mobile-log-out">
        <img src="@/assets/icons/sign-out.png" />              
      </a>    
    </nav>
</template>

<script>
import { useUserStore } from '../utils/user';
import logger from '@/utils/logger'; // Ensure you have the logger utility imported

export default {
  computed: {
    isAuthenticated() {
      const userStore = useUserStore();
      const authStatus = userStore.isAuthenticated;
      logger.addLog(`Mobile navigation: Checked authentication status: ${authStatus ? 'Authenticated' : 'Not Authenticated'}`);
      return authStatus;
    }
  },
  methods: {
    logout() {
      const userStore = useUserStore();
      this.$router.push('/');
      userStore.logout();
      logger.addLog("Mobile navigation: User logged out successfully.");
    }
  }
};
</script>

<style>
  /* #################    Nav    ####################*/
  nav {      
  /* Size */
  width: 100vw;

  /* Position */
  position: fixed;
  bottom: 0;
  z-index: 2;

  /* Grid */
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "profil test abwesenheit abholung logout";

  /* Grid Items */
  align-items: center;
  justify-items: center;              
}

/*Sizing*/
nav ul {  /* Removes the nav text */
    /* Properties */
    display: none;
}

.nav-mobile { 
    /* Size */
    width: 70%;
    height: 70%;
}

#nav-logo {
    /* Properties */
    display:none;
}

nav img{
    /* Size */
    max-height:70%;
    max-width: 70%;
}

/* Nav Items */
#nav-mobile-profil{
    /* Position */
    grid-area: profil;
}

#nav-mobile-test{
    /* Position */
    grid-area: test;
}

#nav-mobile-abwesenheit {
    /* Position */
    grid-area: abwesenheit;
}

#nav-mobile-abholung {
    /* Position */
    grid-area: abholung;
}

#nav-mobile-log-out {
    /* Position */
    grid-area: logout;                  
}       
.nav-desktop {
  display: flex;
  align-items: center;
}

.nav-mobile {
  display: none;
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .nav-desktop {
    display: none !important;
  }

  .nav-mobile {
    display: grid !important;
    position: static; /* Places the navbar below the footer */
  }

  /* Ensures the images/icons are properly sized on mobile */
  nav img {
    max-height: 80%;
    max-width: 80%;
}

}
</style>