<template>
    <h1>FAQ</h1>
    <div>
        <h3>Wie logge ich mich mit einem anderen Kind ein, wenn ich mich bereits mit einem Kind angemeldet habe?</h3>
        <p>Gehe dafür in der Navigationsleiste auf IServ (bzw. das LogOut Icon) und melde dich auf IServ mit dem Konto des anderen Kindes an. Wenn du nicht direkt weitergeleitet wirst, click in der IServ Navigation links auf Meldungen.</p>
    </div>
    <div>
        <h3></h3>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>