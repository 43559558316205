<template>
    <div>
        <h1>Kontakt</h1>
        <p>
         <strong>OGS Waggum</strong><br>
         <br>
        Claudiusstraße 1<br>
        <br>
        38110 Braunschweig<br>
        Telefon: 05307-6544<br>
        Fax: 05307-980280<br>
        Mail: gs.waggum@braunschweig.de<br>
        <br>
        Bürozeiten im Sekretariat (Frau Ludewig): Dienstag, Mittwoch und Freitag 07:30 – 14:00 Uhr<br>
        <br>
         </p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>