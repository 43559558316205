// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import LoginOverview from '../components/LoginOverview.vue';
import AbsenceOverview from '../components/AbsenceOverview.vue';
import ProfileOverview from '../components/ProfileOverview.vue';
import PickupOverview from '../components/PickupOverview.vue';
import ContactOverview from '@/components/secondary/ContactOverview.vue';
import ImprintOverview from '@/components/secondary/ImprintOverview.vue';
import ErrorMessageOverview from '@/components/secondary/ErrorMessageOverview.vue';
import PrivacyPolicyOverview from '@/components/secondary/PrivacyPolicyOverview.vue';
import FaqOverview from '@/components/secondary/FaqOverview.vue';
import OAuthCallback from '@/components/OAuthCallback.vue';
import { useUserStore } from '../utils/user';

const routes = [
  { path: '/', component: LoginOverview, name: 'Login' },
  { path: '/absence', component: AbsenceOverview, name: 'Absence', meta: { requiresAuth: true }},
  { path: '/profile', component: ProfileOverview, name: 'Profile', meta: { requiresAuth: true }},
  { path: '/pickup', component: PickupOverview, name: 'Pickup', meta: { requiresAuth: true }},
  { path: '/contact', component: ContactOverview, name: 'Contact'},
  { path: '/imprint', component: ImprintOverview, name: 'Imprint' },
  { path: '/privacy-policy', component: PrivacyPolicyOverview, name: 'PrivacyPolicy', meta: { requiresAuth: true }},
  { path: '/error', component: ErrorMessageOverview, name: 'Error' },
  { path: '/callback', component: OAuthCallback, name: 'OAuthCallback'},
  { path: '/faq', component: FaqOverview, name: 'Faq' },
  { path: '/:pathMatch(.*)*', redirect: { name: 'Login' }},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Route guard to handle authentication checks and redirects
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();

  // Ensure user authentication status is initialized
  if (userStore.isAuthenticated === false && sessionStorage.getItem('access_token')) {
    await userStore.initializeAuth();
  }

  // Redirect authenticated users away from the Login page to Profile
  if (to.name === 'Login' && userStore.isAuthenticated) {
    next({ name: 'Profile' });
  }
  // Redirect unauthenticated users to Login if route requires auth
  else if (to.meta.requiresAuth && !userStore.isAuthenticated) {
    next({ name: 'Login' });
  } else {
    // Proceed to the requested route
    next();
  }
});

export default router;
