<template>
      <div class="background-images">
        <div class="background-top">
            <img src="@/assets/upper-right-cropped.png" />
        </div>
        <div class="background-bottom">
            <img src="@/assets/lower-left-cropped.png" />
        </div>
    </div>
    <div id="app-container" class="d-flex flex-column" :class="{ 'justify-center-view': isLogin() }">
      <div id="app-content-container" class="align-self-center">
        <NavBarDesktop id="navbar" v-if="!isLogin()"/> 
        <div id="content-container" 
        class="glass d-flex justfify-content-center flex-column gap-4 p-4">   
          <router-view></router-view>
        </div>
        <FooterBar v-if="this.$route.name !== 'Callback' || this.$route.name !== 'Redirecting'"/>
      </div>
    </div>
    <NavBarMobile class="mobile-nav" v-if="!isLogin()"/>

</template>
<script>
import FooterBar from './components/FooterBar.vue';
import NavBarDesktop from './components/NavBarDesktop';
import NavBarMobile from './components/NavBarMobile.vue';
import { useUserStore } from './utils/user';

export default {
  name: 'EduCare',
  components: { 
    FooterBar,
    NavBarDesktop,
    NavBarMobile
  },
  methods: {
    isLogin() {
      const userStore = useUserStore();
      return this.$route.name === 'Login' || userStore.isAuthenticated === false;
    }
  },
}
</script>

<style scoped>
#app-container {
  min-height: 100vh;
}
#app-content-container {
  min-height: 100%;
}
#content-container {
  width: 960px;
  height: auto; 

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

/* Background */
.background-images {   
    /* Size */
    width: 100%;
    min-height: 100%; /* Extends background as tall as the content */

    /* Position */
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    /* Properties */
    user-select: none;
    overflow: hidden;
    pointer-events: none;
}

.background-top {
    /* Size */
    width: 438px;
    height: 438px;

    /* Position */
    position: absolute;
    right: 0;
    top: 0;     
}

.background-bottom {
    /* Size */
    width: 438px;
    height: 438px;

    /* Position */
    position: absolute;
    left: 0;
    bottom: 0; /* Anchors to the bottom of .background-images */
}

.justify-center-view {
  justify-content: center;
}

</style>